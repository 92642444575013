import $ from 'jquery';
import 'bootstrap-multiselect';

import { __ } from 'JIX/gettext.js';

import CompanyMinimumInputLength from 'JIX/select2/CompanyMinimumInputLength.js';
import preventDoubleSubmit from 'JIX/preventdoublesubmit.js';

const stash = window.Stash['jobsearch'] || {};

const JIX = window.JIX = window.JIX || {};
JIX.Jobsearch = JIX.Jobsearch || {};

function init_form(options) {
    const $jobage = $('#jobage');

    if (options.enable_advanced) {
        // Ensure that mindate and maxdate are strings so the datepicker doesn't break
        const mindate = options.archive.mindate.toString();
        const maxdate = options.archive.maxdate.toString();

        const $advanced_component = $("#jobsearch-advanced-archive");
        const $mindate = $("#mindate");
        const $maxdate = $("#maxdate");

        const show_archive_dates = () => {
            $advanced_component.collapse('show');
            $mindate.val(mindate).datepicker('update', mindate);
            $maxdate.val(maxdate).datepicker('update', maxdate);
        };
        const hide_archive_dates = () => {
            $advanced_component.collapse('hide');
            $mindate.val('');
            $maxdate.val('');
        };

        $('#jobsearch-advanced')
            .on('hide.bs.collapse', () => hide_archive_dates())
            .on('show.bs.collapse', () => {
                $("#search-component__advanced_tab_collapsed").hide();
                if ($jobage.val() === 'archive') {
                    show_archive_dates();
                }
            });

        $jobage.on("change", () => {
            if ($jobage.val() === 'archive') {
                show_archive_dates();
            } else {
                hide_archive_dates();
            }
        }).trigger('change');
    }

    $.fn.select2.amd.require(
        ['select2/utils', 'select2/data/ajax'],
        (Utils, AjaxData) => {
            const dataAdapter = Utils.Decorate(AjaxData, CompanyMinimumInputLength);

            $("#companies").jix_select2({
                dataAdapter,
                multiple: true,
                minimumInputLength: 1,
                placeholder: __('Virksomhed'),
                ajax: {
                    url: options.api.find_company,
                    dataType: 'json',
                    delay: 250,
                    data: (params) => {
                        const res = { query: params.term };
                        if ($jobage.val() === 'archive') {
                            res.archive = '1';
                        }
                        return res;
                    },
                    processResults: (results) => {
                        return { results };
                    },
                }
            });
        }
    );

    $("#flag").jix_select2();

    // TODO: Refactor our defaults into a jix_multiselect wrapper like jix_select2
    const multiselect_common = {
        buttonWidth: '100%',
        buttonClass: '',
        inheritClass: true,
        numberDisplayed: 1,
        buttonContainer: '<div class="dropdown"/>',
    };

    $('#category').multiselect({
        ...multiselect_common,
        nonSelectedText: __('Vælg kategorier'),
        nSelectedText: __('kategorier valgt'),
        allSelectedText: __('Alle kategorier valgt'),
        templates: {
            button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown" data-flip="false"><span class="multiselect-selected-text"></span></button>',
            li: '<li><a tabindex="0" class="dropdown-item"><label class="text-wrap"></label></li>',
            // We use the classes on ul and liGroup to increase the font size; we style the height of ul to get a scroll-bar
            //ul: '<ul style="height: 400px;" class="multiselect-container dropdown-menu sc-multiselect-control-md-ul"></ul>',
            //liGroup: '<li class="multiselect-item multiselect-group sc-multiselect-control-md-li-group"><label></label></li>',
            //li: '<li><a href="javascript:void(0);"><label><span class="jix-multiselect-checkbox"></span></label></a></li>'
        },
        onChange: function(option, checked) {
            // HACK: subid's are not unique – subcategories can be present in
            // multiple supercategories. The following is a workaround for the
            // issues that causes.
            const subid = $(option).val();
            const opts = $('#category').find(`option[value=${subid}]`);
            opts.attr('selected', checked ? 'selected' : null).prop('selected', checked);
            $('#category').multiselect('rebuild');
        }
    });
    $jobage.multiselect({
        ...multiselect_common,
        nonSelectedText: __('Vælg alder'),
        templates: {
            button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown" data-flip="false"><span class="multiselect-selected-text"></span></button>',
            li: '<li><a tabindex="0" class="dropdown-item"><label></label></li>',
            // We use the class sc-multiselect-control-md-ul on ul to increase the font size
            //ul: '<ul class="multiselect-container dropdown-menu sc-multiselect-control-md-ul"></ul>',
            //li: '<li><a href="javascript:void(0);"><label><span class="jix-multiselect-radiobutton"></span></label></a></li>'
        }
    });
}

$(() => {
    if (stash.form_options) {
        init_form(stash.form_options);
    }
    document.querySelectorAll('form').forEach((form) => preventDoubleSubmit(form));
});
