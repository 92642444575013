export default function CompanyMinimumInputLength(decorated, $e, options) {
    decorated.call(this, $e, options);
}

CompanyMinimumInputLength.prototype.query = function(decorated, params, callback) {
    const term = (params.term || '').trim();

    if (!/\d|../.test(term)) {
        this.trigger('results:message', {
            message: 'inputTooShort',
            args: {
                minimum: 2,
                input: term,
                params: params
            }
        });

        return;
    }

    decorated.call(this, params, callback);
};
