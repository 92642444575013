export default function preventDoubleSubmit(form, timeout = 1000) {
    form.addEventListener('submit', (event) => {
        if (form.dataset.submitted === true) {
            event.preventDefault();
        } else {
            form.dataset.submitted = true;
            if (timeout > 0) {
                setTimeout(() => { form.dataset.submitted = false; }, timeout);
            }
        }
    });
}
